<template>
	<div>
		<el-table
		    :data="data"
		    border
			empty-text='No Data'
		    class="width-max">
		    <el-table-column
		      prop="name"
		      label="Country"
		      header-align="center">
			</el-table-column>
			<el-table-column label="Agriculture" header-align="center">
				<el-table-column prop="whiteRice" label="ข้าวสารขาว" header-align="center" align="right">
				</el-table-column>
				<el-table-column prop="jasmineRice" label="ข้าวสารหอม" header-align="center" align="right">
				</el-table-column>
			</el-table-column>
			<el-table-column label="Processed" header-align="center">
				<el-table-column prop="whiteRiceCan" label="ข้าวขาวนึ่งกระป๋อง" header-align="center" align="right">
				</el-table-column>
				<el-table-column prop="jasmineRiceCan" label="ข้าวหอมนึ่งกระป๋อง" header-align="center" align="right">
				</el-table-column>
			</el-table-column>
			<el-table-column label="Innovation" header-align="center">
				<el-table-column prop="riceBranOil" label="น้ำมันรำข้าวสกัดบริสุทธิ์" header-align="center" align="right">
				</el-table-column>
				<el-table-column prop="riceEnergyDrink" label="เครื่องดื่มข้าวชูกำลัง" header-align="center" align="right">
				</el-table-column>
		</el-table-column>
		</el-table>
	</div>
</template>
<script>
	export default{
		props:['priceData','data'],
	}
</script>