<template>
  <DashboardTemplate>
    <el-card class="box-card">
      <div>
        <h2>Ranking</h2>
        <Result :data="resultData" />
      </div>
      <h2>World’s Demand</h2>
      <WorldDemand :data="worldData"  />
      <h2>Sold Quantity</h2>
      <SoldQuantity :priceData="priceSoldq" :saleData="saleData" />
      <el-row type="flex" justify="end" class="mg-t-2">
        <el-col :span="6">
          <el-button
            v-if="typeof user.userLevel != 'undefined' && user.userLevel == 80"
            class="mg-y-5 w-100 btn-primary"
            type="primary"
            @click="[confirmModal = true,round = room.currentRound + 1 ]"
            round
          >{{ room.currentRound < 6 ? "Next round" : "End game" }}</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog :visible.sync="confirmModal" width="30%">
        <h3 class="text-center" v-if="round <= 6">เริ่มต้นรอบที่ {{round}}</h3>
        <h3 class="text-center" v-else>End game</h3>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmModal = false">Cancle</el-button>
        <el-button type="primary" @click="round <= 6 ? submit(): endGame()" :loading="loadingConfirm">Confirm</el-button>
      </span>
    </el-dialog>
  </DashboardTemplate>
</template>
<script>
import { HTTP } from "@/service/axios";
import { alertFailError } from "@/service/alertMsg";
import DashboardTemplate from "@/template/DashboardTemplate";
import WorldDemand from "@/components/WorldDemand/WorldDemand";
import SoldQuantity from'@/components/soldQuantity/SoldQuantity'
import Result from'@/components/Result/Result'
export default {
  components: {
    DashboardTemplate,
    WorldDemand,
    SoldQuantity,
    Result
  },
  data(){
    return{
      priceSoldq:[],
      saleData:[],
      resultData:[],
      worldData:[],
      priceData:[
          {
            name:'Amfreland',
            whiteRice:0,
            jasmineRice:0,
            whiteRiceCan:0,
            jasmineRiceCan:0,
            riceBranOil:0,
            riceEnergyDrink:0
          },{
            name:'Belovia',
            whiteRice:0,
            jasmineRice:0,
            whiteRiceCan:0,
            jasmineRiceCan:0,
            riceBranOil:0,
            riceEnergyDrink:0
          }
        ],
      confirmModal:false,
      round:0,
      loadingConfirm:false
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    room() {
      return this.$store.state.room;
    }
  },
  mounted(){
    this.fetchRoom()
    this.fetchResult()
  },
  methods: {
    fetchResult(){
      HTTP.get('/summary/'+this.room._id+'/'+this.room.currentRound).then(res=>{

        if(res.data.success){
          this.resultData = res.data.obj
        }
      })
    },
    async endGame(){
      this.loadingConfirm = true
      this.room.current = 'End Game'
      await this.$store.dispatch('updateCurrent',this.room)
      await this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
      await HTTP.delete('/end/'+this.room._id).then(res=>{
      
        if(res.data.success){
          this.confirmModal = false
           this.$router.push('/create-room').catch(err => {})
          this.loadingConfirm = false
          this.$notify({
            title: 'Room #'+this.room.roomNumber+' has been end.',
            message: 'เกมส์ #'+this.room.roomNumber+' ได้สิ้นสุดแล้ว',
            type: 'success'
          });
        }
      })
    },
    fetchRoom(){
      let obj = {
          number:this.room.roomNumber
      }
      return this.$store.dispatch('FETCH_GROUPS',obj).then(() => {
        this.fetchSoldQ();
        this.fetchDemand()
      });
    },
    async submit() {
      this.loadingConfirm = true
      this.room.current = 'Sale Order'
      this.room.currentRound = this.room.currentRound + 1,
      await this.$store.dispatch('updateCurrent',this.room);
      await this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
      await this.$socket.client.emit('gate',{roomNumber:this.room.roomNumber,currentRound:this.room.currentRound})

      setTimeout(()=>{
        this.$router.push("/payment-term").catch(err => {})
        this.loadingConfirm = false
      },500)  
      
    },
    fetchDemand(){
      this.worldData = []
      HTTP.get('/world/demand/'+this.room.roomNumber+'/'+this.room.currentRound).then(res=>{

        if(res.data.success){
          for (var i = 0; i < 2; i++) {
            let data = {
              name: i == 0 ? 'Amfreland' : 'Belovia',
                whiteRice: i == 0 ? res.data.obj.amfreland.whiteRice : res.data.obj.belovia.whiteRice,
                jasmineRice: i == 0 ? res.data.obj.amfreland.jasmineRice : res.data.obj.belovia.jasmineRice,
                whiteRiceCan: i == 0 ? res.data.obj.amfreland.whiteRiceCan : res.data.obj.belovia.whiteRiceCan,
                jasmineRiceCan: i == 0 ? res.data.obj.amfreland.jasmineRiceCan:res.data.obj.belovia.jasmineRiceCan,
                riceBranOil: i == 0 ? res.data.obj.amfreland.riceBranOil : res.data.obj.belovia.riceBranOil,
                riceEnergyDrink: i == 0 ? res.data.obj.amfreland.riceEnergyDrink : res.data.obj.belovia.riceEnergyDrink,
            }
            this.worldData.push(data)
          }
        }
      })
    },
    fetchSoldQ(){
      HTTP.get('/soldq/'+this.room._id+'/'+this.room.currentRound).then(res=>{

        if(res.data.success){
          let productMax = []
          let allTeam = []
          this.room.groups.map(item=>{
            let data = {}
            let filterUser = res.data.objUser.filter(search=> search.groupId == item._id)
            for (let x = 0; x < 2; x++) {
              if(x == 0){
                data.country = "Amfreland"
              }else{
                data.country = "Belovia"
              }
       
        
        let whiteRiceA = res.data.obj.amfreland.whiteRice.filter(se => se.groupId === item._id )
        let whiteRiceB = res.data.obj.belovia.whiteRice.filter(se => se.groupId === item._id )

        let jasmineRiceA = res.data.obj.amfreland.jasmineRice.filter(se => se.groupId === item._id )
        let jasmineRiceB = res.data.obj.belovia.jasmineRice.filter(se => se.groupId === item._id )

        let whiteRiceCanA = res.data.obj.amfreland.whiteRiceCan.filter(se => se.groupId === item._id )
        let whiteRiceCanB = res.data.obj.belovia.whiteRiceCan.filter(se => se.groupId === item._id )

        let jasmineRiceCanA = res.data.obj.amfreland.jasmineRiceCan.filter(se => se.groupId === item._id )
        let jasmineRiceCanB = res.data.obj.belovia.jasmineRiceCan.filter(se => se.groupId === item._id )

        let riceBranOilA = res.data.obj.amfreland.riceBranOil.filter(se => se.groupId === item._id )
        let riceBranOilB = res.data.obj.belovia.riceBranOil.filter(se => se.groupId === item._id )

        let riceEnergyDrinkA = res.data.obj.amfreland.riceEnergyDrink.filter(se => se.groupId === item._id )
        let riceEnergyDrinkB = res.data.obj.belovia.riceEnergyDrink.filter(se => se.groupId === item._id )

                      data._id = item._id
                      data.name = item.name
                      data.x = x
                      data.cash = filterUser.length > 0 ? filterUser[0].cash : 0
                      data.branding = filterUser.length > 0 ? filterUser[0].branding : 0
                      data.rd = filterUser.length > 0 ? filterUser[0].rd : 0

                            data.whiteRice = {
                                sold:x == 0 ? whiteRiceA.length > 0 ? whiteRiceA[0].s : 0 : whiteRiceB.length > 0 ? whiteRiceB[0].s : 0,
                                price: x == 0 ? whiteRiceA.length > 0 ? whiteRiceA[0].p : 0 : whiteRiceB.length > 0 ? whiteRiceB[0].p : 0,
                                soldq: x == 0 ? whiteRiceA.length > 0 ? whiteRiceA[0].q : 0 : whiteRiceB.length > 0 ? whiteRiceB[0].q : 0,
                                total: (whiteRiceA.length > 0 ? whiteRiceA[0].s : 0 ) + (whiteRiceB.length > 0 ? whiteRiceB[0].s : 0)
                            }
                            data.jasmineRice = {
                                sold:x == 0 ? jasmineRiceA.length > 0 ? jasmineRiceA[0].s : 0 : jasmineRiceB.length > 0 ? jasmineRiceB[0].s : 0,
                                price: x == 0 ? jasmineRiceA.length > 0 ? jasmineRiceA[0].p : 0 : jasmineRiceB.length > 0 ? jasmineRiceB[0].p : 0,
                                soldq: x == 0 ? jasmineRiceA.length > 0 ? jasmineRiceA[0].q : 0 : jasmineRiceB.length > 0 ? jasmineRiceB[0].q : 0,
                                total: (jasmineRiceA.length > 0 ? jasmineRiceA[0].s : 0) + (jasmineRiceB.length > 0 ? jasmineRiceB[0].s : 0)

                            }
                            data.whiteRiceCan = {
                                sold:x == 0 ? whiteRiceCanA.length > 0 ? whiteRiceCanA[0].s : 0 : whiteRiceCanB.length > 0 ? whiteRiceCanB[0].s : 0,
                                price: x == 0 ? whiteRiceCanA.length > 0 ? whiteRiceCanA[0].p : 0 : whiteRiceCanB.length > 0 ? whiteRiceCanB[0].p : 0,
                                soldq: x == 0 ? whiteRiceCanA.length > 0 ? whiteRiceCanA[0].q : 0 :  whiteRiceCanB.length > 0 ? whiteRiceCanB[0].q : 0,
                                total: (whiteRiceCanA.length > 0 ? whiteRiceCanA[0].s : 0) + (whiteRiceCanB.length > 0 ? whiteRiceCanB[0].s : 0)
                            }
                            data.jasmineRiceCan = {
                                sold:x == 0 ? jasmineRiceCanA.length > 0 ? jasmineRiceCanA[0].s : 0 : jasmineRiceCanB.length > 0 ? jasmineRiceCanB[0].s : 0,
                                price: x == 0 ? jasmineRiceCanA.length > 0 ? jasmineRiceCanA[0].p : 0 : jasmineRiceCanB.length > 0 ? jasmineRiceCanB[0].p : 0,
                                soldq: x == 0 ? jasmineRiceCanA.length > 0 ? jasmineRiceCanA[0].q : 0 : jasmineRiceCanB.length > 0 ? jasmineRiceCanB[0].q : 0,
                                total: (jasmineRiceCanA.length > 0 ? jasmineRiceCanA[0].s : 0) + (jasmineRiceCanB.length > 0 ? jasmineRiceCanB[0].s : 0)
                            }
                            data.riceBranOil = {
                                sold:x == 0 ? riceBranOilA.length > 0 ? riceBranOilA[0].s : 0 : riceBranOilB.length > 0 ? riceBranOilB[0].s : 0,
                                price: x == 0 ? riceBranOilA.length > 0 ? riceBranOilA[0].p : 0 : riceBranOilB.length > 0 ? riceBranOilB[0].p : 0,
                                soldq: x == 0 ? riceBranOilA.length > 0 ? riceBranOilA[0].q : 0 : riceBranOilB.length > 0 ? riceBranOilB[0].q : 0,
                                total: (riceBranOilA.length > 0 ? riceBranOilA[0].s : 0) + (riceBranOilB.length > 0 ? riceBranOilB[0].s : 0)
                            }
                            data.riceEnergyDrink = {
                                sold:x == 0 ? riceEnergyDrinkA.length > 0 ? riceEnergyDrinkA[0].s : 0 : riceEnergyDrinkB.length > 0 ? riceEnergyDrinkB[0].s : 0,
                                price: x == 0 ? riceEnergyDrinkA.length > 0 ? riceEnergyDrinkA[0].p : 0 : riceEnergyDrinkB.length > 0 ? riceEnergyDrinkB[0].p : 0,
                                soldq: x == 0 ? riceEnergyDrinkA.length > 0 ? riceEnergyDrinkA[0].q : 0 : riceEnergyDrinkB.length > 0 ? riceEnergyDrinkB[0].q : 0,
                                total: (riceEnergyDrinkA.length > 0 ? riceEnergyDrinkA[0].s : 0) + (riceEnergyDrinkB.length > 0 ? riceEnergyDrinkB[0].s : 0)
                            }
              let dataArr = JSON.parse(JSON.stringify(data))
     
              allTeam.push(dataArr)
            }
          })
          this.saleData = allTeam
          for (let i = 0; i < 2; i++) {
            let country = {
                            name: i==0 ? 'Amfreland':'Belovia',
                            minP1: i==0 ? res.data.obj.amfrelandPrice.min.whiteRice : res.data.obj.beloviaPrice.min.whiteRice,
                            maxP1: i==0 ? res.data.obj.amfrelandPrice.max.maxWhiteRice :  res.data.obj.beloviaPrice.max.maxWhiteRice ,
                            minP2: i==0 ? res.data.obj.amfrelandPrice.min.jasmineRice : res.data.obj.beloviaPrice.min.jasmineRice,
                            maxP2:i==0 ? res.data.obj.amfrelandPrice.max.maxJasmineRice : res.data.obj.beloviaPrice.max.maxJasmineRice,
                            minP3:i==0 ? res.data.obj.amfrelandPrice.min.whiteRiceCan : res.data.obj.beloviaPrice.min.whiteRiceCan,
                            maxP3:i==0 ? res.data.obj.amfrelandPrice.max.maxWhiteRiceCan : res.data.obj.beloviaPrice.max.maxWhiteRiceCan,
                            minP4:i==0 ? res.data.obj.amfrelandPrice.min.jasmineRiceCan : res.data.obj.beloviaPrice.min.jasmineRiceCan,
                            maxP4:i==0 ? res.data.obj.amfrelandPrice.max.maxJasmineRiceCan : res.data.obj.beloviaPrice.max.maxJasmineRiceCan,
                            minP5:i==0 ? res.data.obj.amfrelandPrice.min.riceBranOil : res.data.obj.beloviaPrice.min.riceBranOil,
                            maxP5:i==0 ? res.data.obj.amfrelandPrice.max.maxRiceBranOil : res.data.obj.beloviaPrice.max.maxRiceBranOil,
                            minP6:i==0 ? res.data.obj.amfrelandPrice.min.riceEnergyDrink : res.data.obj.beloviaPrice.min.riceEnergyDrink,
                            maxP6:i==0 ? res.data.obj.amfrelandPrice.max.maxRiceEnergyDrink : res.data.obj.beloviaPrice.max.maxRiceEnergyDrink,
                        }
            productMax.push(country)
          }
          this.priceSoldq = productMax
        }
      })
    },  
  }
};
</script>