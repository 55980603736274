<template>
	<el-table
	border
	:data="data"
	:row-class-name="tableRowClassName"
	:default-sort = "{prop: 'final', order: 'descending'}"
	style="width: 100%">
		<el-table-column
	      type="index"
	      width="70"
	      align="center"
	      label="ลำดับที่">
	    </el-table-column>
		<el-table-column
		prop="groupName"
		header-align="center"
		label="ทีม">
			<template slot-scope="scope" >
				<el-tooltip :content="'Branding: '+$options.filters.comma(scope.row.branding)+' | R&D: '+$options.filters.comma(scope.row.rd)" placement="top" effect="dark">
				  	<span>
						{{scope.row.groupName}}
					</span>
				</el-tooltip>
			</template>
		</el-table-column>
		<el-table-column
		prop="inv"
		align="right"
		header-align="center"
		label="มูลค่าสินค้าคงคลัง">
			<template slot-scope="scope" >
				<div :class="scope.row.inv < 0 ? 'color-danger' : ''">
					{{scope.row.inv | comma}}
				</div>
			</template>
		</el-table-column>
		<el-table-column
		prop="warehouse"
		header-align="center"
		align="right"
		label="มูลค่าโกดัง">
			<template slot-scope="scope" >
				<div :class="scope.row.warehouse < 0 ? 'color-danger' : ''">
					{{scope.row.warehouse | comma}}
				</div>
			</template>
		</el-table-column>	
		<el-table-column
		prop="rd7"
		align="right"
		header-align="center"
		label="ลูกหนี้การค้า">
			<template slot-scope="scope" >
				<div :class="scope.row.rd7 < 0 ? 'color-danger' : ''">
					{{scope.row.rd7 | comma}}
				</div>
			</template>
		</el-table-column>
		<el-table-column
		prop="cash"
		header-align="center"
		align="right"
		label="เงินสดในมือ">
			<template slot-scope="scope" >
				<div :class="scope.row.cash < 0 ? 'color-danger' : ''">
					{{scope.row.cash | comma}}
				</div>
			</template>
		</el-table-column>
		<el-table-column
		prop="debt"
		align="right"
		header-align="center"
		label="หนี้สิน">
			<template slot-scope="scope">
				<div v-if="scope.row.debt > 0" class="color-danger">
					- {{scope.row.debt | comma}}
				</div>
				<div v-else>
					0
				</div>
			</template>
		</el-table-column>
		<el-table-column
		prop="final"
		header-align="center"
		align="right"
		label="มูลค่าทรัพย์สินทั้งหมด">
			<template slot-scope="scope" :class="scope.row.rd7 < 0 ? 'color-danger' : ''">
				<div :class="scope.row.final > 0 ? 'color-success' : 'color-danger'" class="font-weight-bold">
					{{ scope.row.final | comma}}
				</div>
			</template>
		</el-table-column>
	</el-table>
</template>
<script>
export default {
	props:['data'],
	data(){
		return{
			render:false
		}
	},
	computed:{
        user(){
            return this.$store.state.user
        },
    },	
	methods:{
		tableRowClassName({row, rowIndex}) {

			if(this.user.userLevel == 0 && row.groupId == this.user._id){
		          return 'background-color-table';
			} 
			return '';
	        
	    },
	}
}
</script>